import '../sass/style.scss';
import AOS from 'aos';
import lazySizes from "lazysizes";
import './utils/lazybg';
import AutoResizeVideos from './modules/videos/autoresize';
import './modules/case-studies/casestudyimage-carousel';
import './modules/case-studies/filter';
import './modules/testimonials/testimonials-carousel';
import './modules/nav/nav';
import './modules/search/_search';
import 'picturefill';
import AccordionService from './modules/accordion/accordion';
import './modules/galleries/image-gallery';
import VideoResponser from './modules/hero-video/heroVideo';


document.createElement('picture');

AOS.init({
	once: true,
	duration: 1000,

});

AccordionService.init("[data-component='accordion']");
