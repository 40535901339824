function init(elem, option) {
	document.addEventListener('click', function(e) {
		if (!e.target.matches(`${elem} .a-btn`)) return;

		if (!e.target.parentElement.classList.contains('active')) {
			if (option === true) {
				const elementList = document.querySelectorAll(`${elem} .a-container`);

				Array.prototype.forEach.call(elementList, function(el) {
					el.classList.remove('active');
				});
			}
			e.target.parentElement.classList.add('active');
		} else {
			e.target.parentElement.classList.remove('active');
		}
	});
}

const AccordionService = {
	init,
};

export default AccordionService;
