import Tobii from '@midzer/tobii';
const tobii = new Tobii({

});
tobii.on('open', () => {
	const imgHidden = document.getElementsByClassName("img-not-visible");
	if(imgHidden) {
		for(let i = 0; i < imgHidden.length; i++) {
			//imgHidden[i].classList.remove('gallery-card--hidden')
		}
	}
})

