import queryString from 'query-string';

const filtersEl = document.querySelector('[data-filters]');

const handleFilterChange = e => {
	const qs = queryString.parse(location.search);

	qs[e.target.id] = e.target.value;

	history.replaceState(null, null, `?${queryString.stringify(qs)}`);

	const pathSegments = location.pathname.split('/').filter(x => x);

	if (pathSegments[pathSegments.length - 1].startsWith('page-')) {
		pathSegments.pop();
		location.pathname = `/${pathSegments.join('/')}/`;
	} else {
		location.reload();
	}
};

if (filtersEl) {
	const filters = Array.from(filtersEl.querySelectorAll('[data-filter]'));

	filters.forEach(filter => {
		filter.addEventListener('change', handleFilterChange);
	});
}
