import Glide from '@glidejs/glide';

const testimonialCarouselEls = Array.from(
	document.querySelectorAll('[data-carousel="testimonials"]')
);

function createCarousel(el) {
	return new Glide(el, {
		type: 'carousel',
		gap: 16,
		autoplay: 10000,
		hoverpause: true,
		bound: true,
		perView: 2,
		breakpoints: {
			960: {
				perView: 1,
			},
		},
	});
}

if (testimonialCarouselEls) {
	const testimonialCarousels = testimonialCarouselEls.map(el =>
		createCarousel(el)
	);

	testimonialCarousels.forEach(el => el.mount());
}
