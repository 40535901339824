const menu = document.getElementById("nav");
const burger = document.getElementById("burger");
const close = document.getElementById("close");
let width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
const subNav = document.getElementsByClassName("subnav-toggle");
const backBtn = document.getElementsByClassName("back-btn");

function menuControls() {
	if(width < 1150) {
		if(menu && burger) {
			burger.addEventListener("click", (e) => {
				e.preventDefault();
				menu.classList.add("open");
				burger.classList.add("open");
			});
			close.addEventListener("click", () => {
				menu.classList.remove("open");
				burger.classList.remove("open");
				console.log('close');
			})
			if(subNav) {
				for(let i = 0; i < subNav.length; i++) {
					subNav[i].addEventListener("click", (e) => {
						subNav[i].classList.add("open")
					});
				}
			}
			if(backBtn) {
				for(let i = 0; i < backBtn.length; i++) {
					backBtn[i].addEventListener("click", (e) => {
						e.preventDefault();
						backBtn[i].closest(".subNavParent").querySelector(".subnav-toggle").classList.remove("open")
					})
				}
			}

		}
	}
}

onresize = (event) => {
	let width =  (window.innerWidth > 0) ? window.innerWidth : screen.width;
	menuControls(width);
};
menuControls(width);
