const video = document.querySelectorAll(".video");

if(video) {
	video.forEach((item) => {
		const desktopUrl = item.src;
		const mobileUrl = item.dataset.mobile;
		const currentWidth = window.innerWidth;
		if(currentWidth < 1100) {
			item.src = mobileUrl;
		}
		onresize = (event) => {
			let width = window.innerWidth;
			if(width < 1100 && item.src !== mobileUrl) {
				item.src = mobileUrl;
			}
			if(width > 1100 && item.src !== desktopUrl) {
				item.src = desktopUrl;
			}
		}
	});
}