const searchBar = document.getElementById("search-bar");
const searchBtn = document.getElementById("search-btn");

if(searchBar && searchBtn) {
	searchBtn.addEventListener("click", () => {
		if(searchBtn.classList.contains("open")) {
			console.log('contains')
			searchBtn.classList.remove("open");
			searchBar.classList.add("d-none");
		} else {
			searchBtn.classList.add("open");
			searchBar.classList.remove("d-none");
		}
	});
}