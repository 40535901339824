import Glide from '@glidejs/glide';

const caseStudyCarouselEls = Array.from(
	document.querySelectorAll('[data-carousel="casestudyimg"]')
);

function createCarousel(el) {
	return new Glide(el, {
		type: 'slider',
		gap: 0,
		autoplay: 10000,
		hoverpause: true,
		bound: true,
		perView: 1,
	});
}

if (caseStudyCarouselEls) {
	const caseStudyCarousels = caseStudyCarouselEls.map(el => createCarousel(el));

	caseStudyCarousels.forEach(el => el.mount());

	const tab2 = document.querySelector('[data-tab="case-studies"]');

	if (tab2) {
		tab2.addEventListener('click', () => {
			caseStudyCarousels.forEach(el => el.update());
		});
	}
}
